<div class="page__wrapper">
  <app-header></app-header>
  <div class="dashboard" *ngIf="showDashboard">
    <div *ngFor="let alert of displayAlerts()">
      <div class="margin-alert">
        <app-alert
          [alert]="alert"
          [isDashboardAlert]="alert.isDashboardAlert"
          (click)="dismissAlert(alert)"
        ></app-alert>
      </div>
    </div>
  </div>
  <div class="overlay-wrapper">
    <div class="overlay" *ngIf="showAlerts" (scroll)="onScroll($event)">
      <div class="overlay__header">
        <div class="overlay__header__close--alert">
          <app-svg-icon
            iconName="close-light"
            class="overlay__header__close__icon"
            (click)="toggleShowAlerts()"
          ></app-svg-icon>
        </div>
      </div>
      <div class="overlay__alerts" *ngFor="let alert of alerts">
        <app-alert [alert]="alert" [isDashboardAlert]="false"></app-alert>
      </div>
    </div>
    <div class="overlay" *ngIf="showSearch" (scroll)="onScroll($event)">
      <div class="overlay__container">
        <div class="overlay__header">
          <div class="overlay__header__close">
            <app-search-box></app-search-box>
            <app-svg-icon
              iconName="close-light"
              class="overlay__header__close__icon"
              (click)="toggleShowSearch()"
            >
            </app-svg-icon>
          </div>
        </div>
        <div class="overlay__content">
          <div class="overlay__results">
            <app-search-results
              [contentType]="SearchableContent.documents"
            ></app-search-results>
            <app-search-results
              [contentType]="SearchableContent.links"
            ></app-search-results>
            <app-search-results
              [contentType]="SearchableContent.dailyTasks"
            ></app-search-results>
            <app-search-results
              [contentType]="SearchableContent.calendar"
            ></app-search-results>
            <app-search-results
              [contentType]="SearchableContent.needToKnow"
            ></app-search-results>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<app-document-viewer></app-document-viewer>
