import { Component, OnDestroy, HostListener } from '@angular/core';
import { AlertViewModel } from '@models';
import * as searchableContent from '../../data/models/searchable-content';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertService, StateService } from '@app/services';
import { OverlayService } from '@app/services/overlay.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  public SearchableContent = searchableContent.SearchableContent;
  public alerts: AlertViewModel[];
  public dashboardAlerts: AlertViewModel[];
  public showDashboard: boolean;
  public showAlerts: boolean;
  public showSearch = true;
  private lastScrollY = 0;

  constructor(
    private overlayService: OverlayService,
    private alertService: AlertService,
    private router: Router,
    private stateService: StateService
  ) {
    this.initializeComponent();

    this.stateService.stateChanged().subscribe(() => {
      alertService.refreshAlerts();
    });
  }

  @HostListener('click', ['$event'])
  public documentClick(e: any): void {
    if (
      e.target.className === 'header' ||
      e.target.className === 'header__actions' ||
      e.target.className === 'footer__nav'
    ) {
      this.overlayService.toggleShowAlerts(false);
      this.overlayService.toggleShowSearch(false);
    }
  }

  public initializeComponent() {
    this.alertService
      .alerts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((alerts) => {
        this.alerts = alerts;
      });

    this.overlayService
      .getShowAlerts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((show) => {
        this.showAlerts = show;
      });

    this.alertService
      .dashboardAlert()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((alerts) => {
        this.dashboardAlerts = alerts;
      });

    this.alertService
      .showDashboard()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((show) => {
        this.showDashboard = show;
      });

    this.overlayService
      .getShowSearch()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((show) => {
        this.showSearch = show;
      });

    this.hideDashboardAlertOnRoute();

    this.overlayService
      .hideOverlaysOnRoute()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public hideDashboardAlertOnRoute() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.alertService.setShowDashboard(this.router.url === '/');
      });
  }

  public toggleShowAlerts() {
    this.overlayService.toggleShowAlerts();
    this.lastScrollY = 0;
  }

  public toggleShowSearch() {
    this.overlayService.toggleShowSearch();
    this.lastScrollY = 0;
  }

  public onScroll(event: any) {
    const scrollElement = event.target;

    const scrollY = event.target.scrollTop;

    if (scrollY > this.lastScrollY) {
      if (scrollY > 0) {
        scrollElement.classList.add('overlay--sticky');
      }
    } else {
      if (scrollY === 0) {
        scrollElement.classList.remove('overlay--sticky');
      }
    }

    this.lastScrollY = scrollY;
  }

  public dismissAlert(alert: AlertViewModel) {
    this.alertService.hideDashboardAlert(alert.id);
    this.alertService
      .dashboardAlert()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((alerts) => {
        this.dashboardAlerts = alerts;
      });
  }

  public displayAlerts = () => this.dashboardAlerts.slice(0, 3);
}
